<template>
  <div
    @click="onClick"
    :id="label._id"
    class="w-label transition-all duration-400"
    :class="{ 'cursor-pointer': zoomedOut }"
  >
    <div
      v-if="zoomedOut"
      class="absolute top-0 left-0 mt-5 z-10 flex items-center justify-center border border-gray-200 cursor-pointer hover:bg-gray-100 w-6 h-6"
      @click.stop="$store.commit('SELECT_LABEL', label._id)"
      :class="{ 'bg-gray-400 border-gray-400 hover:bg-gray-400': isSelected }"
    >
      <span v-if="isSelected" class="text-xs text-white">✔</span>
    </div>
    <div class="flex justify-between mb-2">
      <!-- STATUS -->
      <div class="relative group" :class="{ 'pointer-events-none': zoomedOut }">
        <div
          class="w-3 h-3 rounded-full"
          :class="[statusBadgeClass, { pulse: updatingStatus }]"
        />
        <div class="absolute z-10 group">
          <ul
            class="absolute bg-white left-0 top-0 hidden group-hover:block border border-gray rounded cursor-pointer divide-y text-sm -mt-6 -ml-4 shadow-lg"
          >
            <li
              v-for="(status, idx) in statuses"
              :key="idx"
              class="py-2 px-4 flex items-center space-x-3 group opacity-30 hover:opacity-100"
              :class="{
                'opacity-100 pointer-events-none':
                  label.status.toLowerCase() === idx ||
                  (!label.status && idx === 'draft'),
              }"
              @click="updateStatus(idx)"
            >
              <div class="w-3 h-3 rounded-full" :class="statuses[idx]" />
              <div class="capitalize" v-text="idx" />
            </li>
          </ul>
        </div>
      </div>

      <!-- FORMAT -->
      <div class="relative group" :class="{ 'pointer-events-none': zoomedOut }">
        <div
          v-text="label.format ? label.format : 'Regular'"
          class="opacity-20 text-xs -mt-1 capitalize w-18 text-right"
          :class="{ pulse: updatingFormat }"
        />
        <div class="absolute z-10 group">
          <ul
            class="absolute bg-white left-0 top-0 hidden group-hover:block border border-gray rounded cursor-pointer divide-y text-sm -mt-6 -ml-8 shadow-lg"
          >
            <li
              v-for="(format, idx) in formats"
              :key="idx"
              class="py-2 px-4 flex items-center space-x-3 group opacity-30 hover:opacity-100"
              :class="{
                'opacity-100 pointer-events-none':
                  label.format &&
                  (label.format.toLowerCase() === idx ||
                    (!label.format && idx === '')),
              }"
              @click="updateFormat(idx)"
            >
              <div class="capitalize text-right w-full" v-text="idx" />
            </li>
          </ul>
        </div>
      </div>

      <!-- HEADER: Template Selector -->
      <!-- <div class="relative group text-sm">
        <div
          class="flex items-center space-x-2 text-gray-400"
          :class="{ 'pulse pointer-events-none': updatingFormat }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
          <span v-text="currentTemplate" />
        </div>
        <div class="absolute z-10 group right-0" v-if="!updatingFormat">
          <ul
            class="absolute bg-white right-0 top-0 hidden group-hover:block border border-gray rounded cursor-pointer divide-y text-sm -mt-6 shadow-lg -mr-2"
          >
            <li
              v-for="(val, key) in templates"
              :key="key"
              class="py-2 px-4 flex items-center space-x-3 group hover:opacity-100"
              :class="{
                'font-bold': key == currentTemplate,
                'opacity-30': key != currentTemplate,
              }"
              @click="updateTemplate(key)"
            >
              <div class="capitalize" v-text="val" />
            </li>
          </ul>
        </div>
      </div> -->
    </div>

    <!-- MAIN LABEL BODY -->
    <div class="relative" :class="{ 'pointer-events-none': saving }">
      <div
        class="label with-padding markable transition-all duration-200 relative"
        :class="{
          'shadow-lg hover:shadow-xl': !zoomedOut,
          'shadow-sm hover:shadow-md': zoomedOut,
          'ring-4 rounded ring-red-300 shadow-xl': dirty,
          'ring-gray-400 ring-4 shadow-md': isSelected && zoomedOut,
        }"
      >
        <editor
          :label="label"
          v-model="label.html"
          @update="update"
          :disabled="saving"
        />

        <transition name="fade">
          <div
            v-if="showCodes"
            class="flex space-x-3"
            :class="{ 'pointer-events-none': saving, 'pt-6': !zoomedOut }"
          >
            <div class="flex space-x-2 items-center">
              <img
                src="../assets/icon-audio.svg"
                :class="{
                  'opacity-20': audioNumber == null || audioNumber == '',
                  'h-10': !zoomedOut,
                  'h-4': zoomedOut,
                }"
              />
              <input
                type="text"
                class="border-gray-300 border-dotted w-4 text-center"
                :class="{ 'w-16 border text-2xl': !zoomedOut }"
                v-model="audioNumber"
              />
            </div>
            <div class="flex space-x-2">
              <img
                src="../assets/icon-visual.svg"
                :class="{
                  'opacity-20': visualNumber == null || visualNumber == '',
                  'h-10': !zoomedOut,
                  'h-4': zoomedOut,
                }"
              />
              <input
                type="text"
                class="border-gray-300 border-dotted w-4 text-center"
                :class="{ 'w-16 border text-2xl': !zoomedOut }"
                v-model="visualNumber"
              />
            </div>
            <div class="flex space-x-2">
              <img
                src="../assets/icon-kids.svg"
                :class="{
                  'opacity-20': kidsNumber == null || kidsNumber == '',
                  'h-10': !zoomedOut,
                  'h-4': zoomedOut,
                }"
              />
              <input
                type="text"
                class="border-gray-300 border-dotted w-4 text-center"
                :class="{ 'w-16 border text-2xl': !zoomedOut }"
                v-model="kidsNumber"
              />
            </div>
          </div>
        </transition>

        <transition name="fade">
          <div v-if="showCodes" class="flex items-center text-xs">
            <input
              :id="`audioEnglishOnly-${label._id}`"
              type="checkbox"
              v-model="audioEnglishOnly"
            />
            <label
              :for="`audioEnglishOnly-${label._id}`"
              class="ml-2 transition duration-300 cursor-pointer"
              :class="{ 'opacity-50': !audioEnglishOnly }"
              >English Only</label
            >
          </div>
        </transition>

        <div
          v-if="saving"
          class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-60"
        >
          <span class="pulse -mt-6">Saving...</span>
        </div>
      </div>

      <!-- FOOTER -->
      <div class="flex items-center justify-between mt-2 text-gray-400 mx-2">
        <div class="flex items-center text-xs space-x-3" v-if="!zoomedOut">
          <div v-if="lastEdit && lastEdit.user" v-text="lastEdit.user.name" />
          <div v-if="lastEdit" class="group">
            <span
              :class="{ 'group-hover:hidden': !zoomedOut }"
              v-text="getTimeAgo(lastEdit.ts)"
            />
            <span
              class="hidden"
              :class="{ 'group-hover:inline-block': !zoomedOut }"
              v-text="formatTimestamp(lastEdit.ts)"
            />
          </div>
          <span
            v-if="!zoomedOut"
            class="text-gray-400 text-xs transition-all duration-400"
            >{{ label._id.slice(-8) }}</span
          >
        </div>
        <router-link
          :to="`/group/${label.group.id}/${label.group.name}#${label._id}`"
          class="text-xs block text-right"
          v-if="showGroup && label.group && label.group.name"
          >{{ label.group.name }} ⤴</router-link
        >
      </div>
      <div
        v-show="!zoomedOut"
        class="absolute right-0 top-0 flex flex-col items-center justify-between hover-toggle space-y-3 mt-2 -mr-10 h-full"
      >
        <div>
          <CloudUploadIcon
            @click="save"
            class="transition-all duration-500 cursor-pointer mb-2"
            :class="{
              'opacity-20 disabled': !dirty,
              'opacity-100': dirty,
              pulse: saving,
            }"
            title="Save Changes"
          />
          <a :href="downloadLink" target="_blank"
            ><DownloadIcon class="icon" :class="{ hidden: saving }"
          /></a>
          <div class="pt-6" :class="{ hidden: saving }" @click="deleteLabel">
            <TrashIcon class="icon" />
          </div>
        </div>
        <div class="pb-4">
          <HashtagIcon class="icon" @click="forceShowCodes = !forceShowCodes" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CloudUploadIcon,
  DownloadIcon,
  TrashIcon,
  HashtagIcon,
} from "@vue-hero-icons/outline";
import Editor from "@/components/Editor.vue";
import ModalDeleteLabel from "@/components/Modals/ModalDeleteLabel";

export default {
  name: "Label",
  props: ["label", "zoomedOut", "showGroup"],
  components: {
    Editor,
    CloudUploadIcon,
    DownloadIcon,
    TrashIcon,
    HashtagIcon,
  },
  data() {
    return {
      originalContent: this.$props.label.html.replaceAll("\n", ""),
      editorContent: this.$props.label.html.replaceAll("\n", ""),
      saving: false,
      updatingStatus: false,
      updatingFormat: false,
      // statuses: [
      //   {
      //     status: 'draft',
      //     color: 'bg-gray-200',
      //   },
      //   {
      //     status: 'editorial',
      //     color: 'bg-yellow-400',
      //   },
      //   {
      //     status: 'production',
      //     color: 'bg-green-400',
      //   },
      //   {
      //     status: 'archived',
      //     color: 'bg-gray-400',
      //   },
      // ],
      statuses: {
        draft: "bg-red-300",
        editorial: "bg-yellow-400",
        production: "bg-green-400",
        archived: "bg-gray-500",
      },
      forceShowCodes: false,
      formatOriginal: this.$props.label.format,
      format: this.$props.label.format || "Regular",
      audioNumberOriginal: this.$props.label.audioNumber
        ? this.$props.label.audioNumber
        : null,
      audioNumber: this.$props.label.audioNumber
        ? this.$props.label.audioNumber
        : null,
      visualNumberOriginal: this.$props.label.visualNumber
        ? this.$props.label.visualNumber
        : null,
      visualNumber: this.$props.label.visualNumber
        ? this.$props.label.visualNumber
        : null,
      kidsNumberOriginal: this.$props.label.kidsNumber
        ? this.$props.label.kidsNumber
        : null,
      kidsNumber: this.$props.label.kidsNumber
        ? this.$props.label.kidsNumber
        : null,
      audioEnglishOnlyOriginal: Object.hasOwn(
        this.$props.label,
        "audioEnglishOnly",
      )
        ? this.$props.label.audioEnglishOnly == true
        : false,
      audioEnglishOnly: Object.hasOwn(this.$props.label, "audioEnglishOnly")
        ? this.$props.label.audioEnglishOnly == true
        : false,
      formats: {
        regular: "Regular",
        case: "Case",
        platform: "Platform",
      },
    };
  },
  computed: {
    currentFormat() {
      if (Object.hasOwn(this.$props.label, "format")) {
        if (
          this.$props.label.template === "" ||
          this.$props.label.template === "Regular"
        ) {
          return "Regular";
        } else {
          return this.templates[this.$props.label.format];
        }
      } else {
        return "Regular";
      }
    },
    lastEdit() {
      if (this.label._updates.length) {
        return this.label._updates[this.label._updates.length - 1];
      } else {
        return false;
      }
    },
    dirty() {
      return (
        this.originalContent != this.editorContent ||
        this.audioNumberOriginal != this.audioNumber ||
        this.visualNumberOriginal != this.visualNumber ||
        this.kidsNumberOriginal != this.kidsNumber ||
        this.audioEnglishOnlyOriginal != this.audioEnglishOnly
      );
    },
    dirtyLabel() {
      const newLabel = this.label;
      newLabel.html = this.editorContent;
      newLabel.audioNumber = this.audioNumber;
      newLabel.visualNumber = this.visualNumber;
      newLabel.kidsNumber = this.kidsNumber;
      newLabel.audioEnglishOnly = this.audioEnglishOnly;
      return newLabel;
    },
    downloadLink() {
      return `${process.env.VUE_APP_API_URL}/export/labels/${this.$props.label._id}.pdf`;
    },
    statusBadgeClass() {
      if (this.$props.label.status) {
        return this.statuses[this.$props.label.status];
      } else {
        return this.statuses["draft"];
      }
    },
    isSelected() {
      // return true
      return this.$store.state.selectedLabels.includes(this.$props.label._id);
    },
    showCodes() {
      return (
        this.label.audioNumber ||
        this.label.kidsNumber ||
        this.label.visualNumber ||
        this.forceShowCodes
      );
    },
  },
  methods: {
    update(content) {
      this.editorContent = content;
    },
    // async updateTemplate(template) {
    //   if (this.dirty) {
    //     alert('You have unsaved changes')
    //   } else {
    //     this.updatingFormat = true
    //     const newLabel = this.label
    //     if (template === 'default' || template === '') {
    //       newLabel.template = ''
    //     } else {
    //       newLabel.template = template
    //     }
    //     let result = await this.$store.dispatch('updateLabel', newLabel)

    //     if (result) {
    //       window.setTimeout(() => {
    //         this.updatingFormat = false
    //       }, 1000)
    //     }
    //   }
    // },
    async updateStatus(idx) {
      if (this.dirty) {
        alert("You have unsaved changes");
      } else {
        this.updatingStatus = true;
        const newLabel = this.label;
        if (idx === "draft") {
          newLabel.status = "";
        } else {
          newLabel.status = idx;
        }
        let result = await this.$store.dispatch("updateLabel", newLabel);

        if (result) {
          // GTAG Label Status
          this.$gtag.event("Update Status", {
            event_category: "Labels",
            event_label: this.label._id,
            value: this.$router.currentRoute.path + "#" + this.label._id,
          });
          window.setTimeout(() => {
            this.updatingStatus = false;
          }, 1000);
        }
      }
    },
    async updateFormat(idx) {
      if (this.dirty) {
        alert("You have unsaved changes");
      } else {
        this.updatingFormat = true;
        const newLabel = this.label;
        if (idx === "" || idx === "regular") {
          newLabel.format = "";
        } else {
          newLabel.format = idx;
        }
        let result = await this.$store.dispatch("updateLabel", newLabel);

        if (result) {
          // GTAG Label Status
          this.$gtag.event("Update Format", {
            event_category: "Labels",
            event_label: this.label._id,
            value: this.$router.currentRoute.path + "#" + this.label._id,
          });
          window.setTimeout(() => {
            this.updatingFormat = false;
          }, 1000);
        }
      }
    },
    async save() {
      if (this.dirty) {
        this.saving = true;
        let result = await this.$store.dispatch("updateLabel", this.dirtyLabel);

        if (result) {
          // GTAG Label Status
          this.$gtag.event("Update Content", {
            event_category: "Labels",
            event_label: this.label._id,
            value: this.$router.currentRoute.path + "#" + this.label._id,
            audioNumber: this.audioNumber,
            visualNumber: this.visualNumber,
            kidsNumber: this.kidsNumber,
            audioEnglishOnly: this.audioEnglishOnly,
            format: this.format,
            template: this.template,
          });
          window.setTimeout(() => {
            this.originalContent = this.editorContent;
            this.audioNumberOriginal = this.audioNumber;
            this.visualNumberOriginal = this.visualNumber;
            this.kidsNumberOriginal = this.kidsNumber;
            this.audioEnglishOnlyOriginal = this.audioEnglishOnly;
            this.formatOriginal = this.format;
            this.formatOriginal = this.format;
            this.saving = false;
          }, 1000);
        }
      }
    },
    onClick(e) {
      if (this.$store.state.zoomedOut) {
        this.$store.commit("SET_ZOOM", false);
        window.setTimeout(() => {
          this.$scrollTo(e.target, 100, {
            offset: -200,
          });
        }, 200);
      }
    },
    deleteLabel() {
      this.$modal.show(
        ModalDeleteLabel,
        { label: this.$props.label._id },
        { height: "auto" },
        // { "before-open": this.beforeOpen, "before-close": this.beforeClose }
      );
    },
  },
  mounted() {
    this._keyListener = function (e) {
      if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault(); // present "Save Page" from getting triggered.

        this.save();
      }
    };

    document.addEventListener("keydown", this._keyListener.bind(this));
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this._keyListener);
  },
};
</script>

<style lang="css"></style>
