<template>
  <div>
    <bubble-menu
      class="menu"
      :editor="editor"
      v-if="editor"
      :tippyOptions="{ maxWidth: '' }"
    >
      <button
        class="menu-button"
        @click="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
        title="Bold"
      >
        <strong>Bold</strong>
      </button>
      <button
        title="Italic"
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        <em>Italic</em>
      </button>
      <button
        title="Large"
        @click="editor.chain().focus().toggleLarge().run()"
        :class="{
          'is-active': editor.isActive('large'),
        }"
      >
        Large
      </button>
      <button
        title="Uppercase"
        @click="editor.chain().focus().toggleUppercase().run()"
        :class="{
          'is-active': editor.isActive('uppercase'),
        }"
      >
        CAPS
      </button>
      <div
        class="cursor-pointer ml-2 border-l border-black px-4 py-2 hover:bg-black hover:text-white transition-all duration-400"
        title="Query by Object ID"
        @click="addObject()"
      >
        TMS
      </div>
    </bubble-menu>
    <editor-content
      :editor="editor"
      :class="[
        { disabled: $attrs.disabled, a_and_d: label.template === 'a_and_d' },
      ]"
    />
  </div>
</template>

<script>
import { DOMParser } from "prosemirror-model";
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2";
import { Uppercase } from "../tiptap-extensions/Uppercase";
import { Large } from "../tiptap-extensions/Large";
import StarterKit from "@tiptap/starter-kit";
import api from "../api";
import ModalError from "@/components/Modals/ModalError";
import { Paragraph } from "@tiptap/extension-paragraph";

export default {
  components: {
    EditorContent,
    BubbleMenu,
  },

  props: ["label"],

  data() {
    return {
      editor: null,
    };
  },

  mounted() {
    const CustomParagraph = Paragraph.extend({
      addAttributes() {
        // Return an object with attribute configuration
        return {
          "data-tms": {},
        };
      },
    });

    this.editor = new Editor({
      content: this.$attrs.value,
      extensions: [StarterKit, Large, CustomParagraph, Uppercase],
      editable: true,
      parseOptions: {
        preserveWhitespace: "full",
      },
      onUpdate: () => {
        this.$emit("update", this.editor.getHTML());
      },
    });
  },

  methods: {
    api,
    addObject() {
      const { state } = this.editor;
      const { from, to } = state.selection;
      const tmsid = state.doc.textBetween(from, to, " ");

      this.api(this, `/blocks/fromobject/${tmsid}`).then((data) => {
        if (data.results.length) {
          const element = document.createElement("div");
          element.innerHTML = data.results.map((x) => x.text.trim()).join("");
          // element.getElementsByTagName('p').forEach(x => x.dataset.tms = tmsid)
          const slice = DOMParser.fromSchema(state.schema).parseSlice(element);
          const transaction = state.tr.insert(
            state.selection.anchor,
            slice.content,
          );
          this.editor.view.dispatch(transaction);
          this.editor.commands.deleteSelection();
        }

        if (data.status !== "error" && data.errors.length) {
          const errors = data.errors.join(",");
          this.$modal.show(
            ModalError,
            {
              title: "Object not found",
              text: `Unfortunately we were unable to find the following: <strong>${errors}</strong>.`,
            },
            { height: "auto" },
          );
        }
      });
    },
  },

  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  },
};
</script>

<style lang="css" scoped>
::v-deep {
  .ProseMirror p.is-editor-empty:first-child::before {
    content: attr(data-placeholder);
    float: left;
    color: #ced4da;
    pointer-events: none;
    height: 0;
  }
}

.menu {
  @apply pl-4 border-2 border-black bg-white rounded flex items-center text-sm;
  button {
    @apply py-2 px-2 text-center opacity-50;

    &.is-active {
      @apply opacity-100;
    }

    &:not(.is-active):hover {
      @apply opacity-70;
    }
  }
}

.disabled {
  pointer-events: none;
}

>>> .ProseMirror {
  @apply outline-none;

  &.ProseMirror-focused {
    @apply outline-none;
  }
}

.zoomed-out {
  >>> .ProseMirror {
    h1 {
      font-size: 16.5px;
    }
  }
}
</style>
