<template>
  <div class="modal-content">
    <h2 class="font-bold text-center">
      Are you sure you want to delete the label?
    </h2>
    <span
      class="text-red-500 text-2xl text-center py-8"
      v-text="$attrs.label.name"
    ></span>
    <form id="deleteGroup" @submit.prevent="submit">
      <div class="flex justify-between space-x-4">
        <button type="button" @click="$emit('close')" :disabled="submitting">
          Cancel
        </button>
        <button
          type="submit"
          :disabled="submitting"
          class="text-gray-300 hover:text-red-500"
        >
          Delete
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "ModalDeleteLabel",
  data() {
    return {
      name: "",
      submitting: false,
    };
  },
  methods: {
    submit() {
      this.$store.dispatch("deleteLabel", this.$attrs.label);
      this.$emit("close");
    },
  },
};
</script>
