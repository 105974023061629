import { Mark } from "@tiptap/core";
const Large = Mark.create({
  name: "large",
  parseHTML: function () {
    return [
      {
        tag: "large",
      },
    ];
  },
  renderHTML: function () {
    return ["large"];
  },
  addCommands: function () {
    var _this = this;
    return {
      setLarge: function () {
        return function (_a) {
          var commands = _a.commands;
          return commands.setMark(_this.name);
        };
      },
      toggleLarge: function () {
        return function (_a) {
          var commands = _a.commands;
          return commands.toggleMark(_this.name);
        };
      },
      unsetLarge: function () {
        return function (_a) {
          var commands = _a.commands;
          return commands.unsetMark(_this.name);
        };
      },
    };
  },
});

export { Large };
