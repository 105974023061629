<template>
  <div class="modal-content text-center h-auto">
    <h2 class="font-bold"><span v-text="$attrs.title"></span></h2>
    <p v-html="$attrs.text"></p>
    <button type="button" @click="$emit('close')">Close</button>
  </div>
</template>

<script>
export default {
  name: "ModalErrorGroup",
};
</script>
